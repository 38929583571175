import Vue from "vue";

import BaseInfoCard                  from "./shared/BaseInfoCard.vue";
import BaseSectionHeading            from "./shared/BaseSectionHeading.vue";
import BaseSection                   from "./shared/BaseSection.vue";
import BaseSubHeading                from "./shared/BaseSubHeading.vue";
import BaseIcon                      from "./shared/BaseIcon.vue";
import BaseTitle                     from "./shared/BaseTitle.vue";
import BaseDivider                   from "./shared/BaseDivider.vue";
import BaseBody                      from "./shared/BaseBody.vue";
import MasterPage                    from "./shared/MasterPage.vue"

Vue.component("base-info-card", BaseInfoCard);
Vue.component("base-section-heading", BaseSectionHeading);
Vue.component("base-section", BaseSection);
Vue.component("base-subheading", BaseSubHeading);
Vue.component("base-icon", BaseIcon);
Vue.component("base-title", BaseTitle);
Vue.component("base-divider", BaseDivider);
Vue.component("base-body", BaseBody);
Vue.component("master-page", MasterPage);