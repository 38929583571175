









































































































































import MasterPage from "@/components/shared/MasterPage.vue";
import { Component, Watch } from "vue-property-decorator";
import { EmailService } from "@/core/services/EmailService";
import { Email } from "@/core/models/Email";

@Component({
  metaInfo() {
    return {
      title: 'Home'
    }
  }
})

export default class Home extends MasterPage {
  
  service = new EmailService();
  contato = new Email();
  valid = true;
  overlay = false;
  snackbar = false;
  snackbarText = '';
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  emailRules: any[] = [(v: any) => !!v || "Campo obrigatório", (v: any) => /.+@.+\..+/.test(v) || "O e-mail não é válido",];
  mskTelefone = process.env.VUE_APP_MSK_TELEFONE;
  $refs!: {
    formContato: HTMLFormElement
  }
  cards = [
    {
      icon: 'mdi-keyboard-outline',
      title: 'Sob medida',
      text: 'Entendemos que cada empresa possui necessidades especificas para seu negócio, com isso possuímos o serviço de desenvolvimento sob medida, para que seja desenvolvido a solução ideal.',
    },
    {
      icon: 'mdi-cellphone',
      title: 'Mobile',
      text: 'Esteja mais perto de clientes, disponibilize dados e automatize processos com o time de desenvolvimento da Giom. Atuamos com desenvolvimento para Android e IOS.',
    },
    {
      icon: 'mdi-cloud-print-outline',
      title: 'Documentos Fiscais',
      text: 'Soluções para facilitar as empresas que necessitam emitir documentos fiscais, como NF-e,  NFS-e, CT-e e MDF-e, o sistema visa a simplicitade tornarndo o processo fácil e sem burocracia.',
    },
    {
      icon: 'mdi-cloud-outline',
      title: 'Ambiente Cloud',
      text: 'Não se preocupe com servidores e licenças, a Giom possui um ambiente dedicado em datacenters seguros, dessa forma os clientes contam com total segurança das suas informações.',
    },
  ]

  @Watch('item')
  Item() {
    if (this.$refs.formContato) {
      this.$refs.formContato.resetValidation();
    }
  }

  public EnviarContato(){
    
    if (this.$refs.formContato.validate()) {
      
      this.overlay = true;
      this.service.EnviarContato(this.contato)
      .then(
        res => {
          this.snackbar = true;
          this.snackbarText = res.data;
          this.contato = new Email();
          this.$refs.formContato.resetValidation();
        },
        err => this.snackbarText = err.response.data
      )
      .finally(() => this.overlay = false);

    }

  }
}

