










  import { Vue, Component } from "vue-property-decorator";

@Component({
    name: 'BaseIcon',
    props: {
    size: {
        type: [Number, String],
        default: 56,
    },
  }
})

export default class BaseIcon extends Vue {

}
