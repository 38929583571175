










































import { Vue, Component } from "vue-property-decorator";


@Component({
    metaInfo() {
       return {
        title: 'Home',
        titleTemplate: '%s | Giom tecnologia',
        htmlAttrs: { lang: 'pt-BR' },
        meta: [
          { charset: 'utf-8' },
          { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        ],
      }
   }
})

export default class MasterPage extends Vue {
  
    drawer = false;
    titulo = 'Home'

    GoTo(anchor: string){
        this.$vuetify.goTo(`#${anchor}`); 
        this.drawer = false;
    }

    Drawer(){
        this.drawer = !this.drawer;
    }

};
