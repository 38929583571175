



























import { Vue, Component } from "vue-property-decorator";
import { Heading } from '@/assets/mixins/heading';

@Component({
    name: 'BaseInfoCard',
    mixins: [Heading],
    props: {
      dark: Boolean,
      callout: String,
      color: {
        type: String,
        default: 'primary',
      },
      icon: String,
      subtitle: String,
      text: String,
      title: String,
    },
})

export default class BaseInfoCard extends Vue {

}
