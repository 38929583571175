import axios from "axios";

export const httpHeader = {
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
  }
};
export const http = axios.create({
  baseURL: process.env.NODE_ENV == 'development' ? 'http://localhost:57002/v1/' : 'https://api-sender.giomtecnologia.com.br/v1/',
  headers: httpHeader,
});
