<script>
  import BaseHeading from './BaseHeading'
  import { Heading } from '@/assets/mixins/heading'

  export default {
    name: 'BaseTitle',

    extends: BaseHeading,

    mixins: [Heading],

    props: {
      size: {
        type: String,
        default: 'text-h6',
      },
      mobileSize: {
        type: String,
        default: 'text-subtitle-1',
      },
      tag: {
        type: String,
        default: 'h3',
      },
      weight: {
        type: String,
        default: 'bold',
      },
    },
  }
</script>
