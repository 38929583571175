import Vue from 'vue';
import Vuetify, { colors } from 'vuetify/lib';
import pt from 'vuetify/src/locale/pt';

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
      locales: { pt },
      current: 'pt',
    },
    theme: {
      dark: false,
      default: 'light',
      themes: { 
        light: {
          primary: '#21b9de',
          secondary: '#0069ab'
        },
        dark: {
          primary: '#78909C',
          secondary: '#424242'
        }
       },
    }
});
