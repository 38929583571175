import { http } from '@/core/ApiConfig';
import { Email } from '@/core/models/Email';

export class EmailService{

    private _nomeControle: string = '';

    constructor() {
        this._nomeControle = 'email';
    }

    public EnviarContato(model: Email){

        return http.post(`${this._nomeControle}/giom/contato`, model);

    }

}